




import { Vue, Component } from "vue-property-decorator";
import CommunicationTemplates from "@/views/Communication/CommunicationTemplates.vue";

@Component({
  components: { CommunicationTemplates },
})
export default class BroadcastTemplates extends Vue {
  public name = "BroadcastTemplates";
}
